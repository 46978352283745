import React, { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import TopHeading from "./Compontents/elements/TopHeading.js";
import { Input } from "semantic-ui-react";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import { Button } from "semantic-ui-react";
import InvoiceTypeDropdown from "./Compontents/elements/InvoiceTypeDropdown.js";
import CurrencyDropdown from "./Compontents/elements/CurrencyDropdown.js";
import BillingDropDown from "./Compontents/elements/BillingDropDown.js";
import { MDBInput } from "mdbreact";
import { Dimmer, Loader, Image, Segment } from "semantic-ui-react";


const axios = require("axios");

export default function SCQForInvoice() {
  const [startDate, set_startDate] = React.useState("");
  const [endDate, set_endDate] = React.useState("");
  const [scid, set_scid] = React.useState("");
  const [invoiceType, set_invoiceType] = React.useState("");
  const [isClicked, set_isClicked] = React.useState(false);
  const [checked, setChecked] = useState(false);
  const [data, set_data] = React.useState([]);
  const [loadedData, setloadedData] = useState([]);
  const [totalFee, set_totalFee] = useState([]);
  const [BillingLocation, set_BillingLocation] = useState([]);
  const [Currency, set_Currency] = useState([]);
  const [InvoiceWOSCID, set_InvoiceWOSCID] = useState(false);
  const [RTInvoice, set_RTInvoice] = useState(false);
  const InvoiceInformationData = [];
  const [Loading, set_Loading] = useState(false);
  const [InvoiceData, set_InvoiceData] = useState([]);
  let d = localStorage.getItem("emp_data"); //15 mar 2022
  const userid = JSON.parse(d).user_id        //15 mar 2022
  useEffect(() => {
    CallForInvoiceWithoutSCID();

  }, [invoiceType])


  function getCurrecny() {
    axios
      .get("/Invoice/proc_NewInvoiceCreate?type=24")
      .then(function (response) {
        set_Currency(response.data);
      })
      .catch(function (error) {
        alert(error);
      });
  }
  function getBillingLocation() {
    axios
      .get("/Invoice/proc_NewInvoiceCreate?type=25")
      .then(function (response) {
        set_BillingLocation(response.data);
      })
      .catch(function (error) {
        alert(error);
      });
  }

  const CallForInvoiceWithoutSCID = () => {
    if (invoiceType == 2) {
      getCurrecny();
      getBillingLocation();
      set_InvoiceWOSCID(true); set_RTInvoice(false); setloadedData([]); set_InvoiceData([]); set_scid(""); localStorage.setItem("SelectedScids", "");

    }
    else if (invoiceType == 3) {
      getCurrecny();
      getBillingLocation();
      set_InvoiceWOSCID(true); set_RTInvoice(false); setloadedData([]); set_InvoiceData([]); set_scid(""); localStorage.setItem("SelectedScids", "");

      //set_RTInvoice(true); set_InvoiceWOSCID(false); setloadedData([]); set_InvoiceData([]); set_scid(""); localStorage.setItem("SelectedScids", "");

    }

    else {
      set_BillingLocation([])
      set_Currency([])
      set_InvoiceWOSCID(false); set_RTInvoice(false);
    }
  }


  const callForLoader = () => {
    return (
      <Segment style={{ height: "100vh" }}>
        <Dimmer active inverted>
          <Loader inverted>Loading</Loader>
        </Dimmer>

        <Image src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png" />
      </Segment>
    );
  };


  async function CallForSCIDData() {
    let js_start = new Date(startDate);
    let js_end = new Date(endDate);

    const sqlParam = {
      scid: scid,
      type: invoiceType,
      startdate:
        startDate == ""
          ? "01/14/2015"
          : js_start.getMonth() +
          1 +
          "/" +
          js_start.getDate() +
          "/" +
          js_start.getFullYear(),
      enddate:
        endDate == ""
          ? "01/14/2030"
          : js_end.getMonth() +
          1 +
          "/" +
          js_end.getDate() +
          "/" +
          js_end.getFullYear(),
      noinvoice: 0,
      userId: userid
    };

    let { data } = await axios.get(
      "/Invoice/proc_NewInvoiceCreate?type=" +
      sqlParam.type +
      "&CurrIds=" +
      sqlParam.scid +
      "&SDate=" +
      sqlParam.startdate +
      "&EDate=" +
      sqlParam.enddate +
      "&noinvoice=" +
      sqlParam.noinvoice
      +
      "&UserId=" +
      sqlParam.userId
    );
    set_isClicked(false);
    if (data.length > 0) {
      setloadedData("");
      setloadedData(data);
      console.log(data)
    }
  }


  async function CallForInvoiceData() {
    const sqlParams = {
      scid: scid,
      type: 2,
      userId: userid
    };
    try {
      await axios.get(
        "/Invoice/proc_NewInvoiceCreate?type=2" +
        "&CurrIds=" +
        sqlParams.scid
        +
        "&UserId=" +
        sqlParams.userId
      ).then(function (response) {
        debugger;
        set_InvoiceData(response.data);
      })
    }
    catch (error) {
      console.log(error.response);
      return error.response;
    }

  }



  function btnSearch() {
    set_data([]);
    set_Loading(true)
    if (invoiceType != 2) {
      CallForSCIDData();
      CallForInvoiceData();
    }

    set_Loading(false)
  }




  async function insertData() {
    debugger;
    set_Loading(true);
    await axios.get(
      "/Invoice/proc_NewInvoiceCreate?type=26" +
      "&billingLocation=" +
      BillingLocation +
      "&noinvoice=" +
      Currency + "&noclientcountry=0"
    ).then(function (response) {
      debugger;
      response.data.map((value) => {
        debugger;
        let { INVId } = value;
        if (INVId != "") {
          set_Loading(false);
          // window.open("/InvoiceSecondStep?invid=" + INVId,"_blank")
          window.location.href = "/InvoiceSecondStep?invid=" + INVId;
        }
      });
    })
      .catch(function (error) {
        console.log(error)
        alert("Invoice not save");
      });
  }

  async function insertDataPI() {
    debugger;
    set_Loading(true);
    await axios.get(
      "/Invoice/proc_NewInvoiceCreate?type=26" +
      "&billingLocation=" +
      BillingLocation +
      "&noinvoice=" +
      Currency + "&noclientcountry=1"
    ).then(function (response) {
      debugger;
      response.data.map((value) => {
        debugger;
        let { INVId } = value;
        if (INVId != "") {
          set_Loading(false);
          // window.open("/InvoiceSecondStep?invid=" + INVId,"_blank")
          window.location.href = "/InvoiceSecondStep?invid=" + INVId;
        }
      });
    })
      .catch(function (error) {
        console.log(error)
        alert("Invoice not save");
      });
  }




  async function insertDataRayontara() {
    debugger;
    set_Loading(true);
    await axios.get(
      "/Invoice/proc_NewInvoiceCreate?type=38" +
      "&billingLocation=" +
      1 +
      "&noinvoice=" +
      Currency
    ).then(function (response) {
      debugger;
      response.data.map((value) => {
        debugger;
        let { INVId } = value;
        if (INVId != "") {
          set_Loading(false);
          // window.open("/InvoiceSecondStep?invid=" + INVId,"_blank")
          window.location.href = "/InvoiceSecondStep?invid=" + INVId;
        }
      });
    })
      .catch(function (error) {
        console.log(error)
        alert("Invoice not save");
      });
  }



  async function btnInvoice() {
    console.log("Invoice Type" + invoiceType);


    if (invoiceType == 2) {
      if ((Currency > 0) && (BillingLocation > 0)) {
        insertData();
      }
      else {
        alert("Please select Currency and Location")
      }
    }
    if (invoiceType == 3) {
      if ((Currency > 0)) {
        insertDataPI()
        //insertDataRayontara()
      }
      else {
        alert("Please select Currency")
      }
    }
    else {
      let isTotalFeeGreaterThanZero = 0;
      totalFee.map((i) => {
        if (i.totalfee > 0) {
          isTotalFeeGreaterThanZero = isTotalFeeGreaterThanZero + 1;
        }
      });

      let checkdata = 0
      if (InvoiceData.length > 0) {
        checkdata = InvoiceData[0].InvoiceNo;
      }
      if (InvoiceData.length > 0 && ((userid != "138") && (userid != "14731") && (userid != "167") && (userid != "1207") && (userid != "14633") && (userid != "15415") && (userid != "8948")) && (!checkdata.includes("KSL-PI"))) {
        alert("Invoice already created");
      }
      else {
        if (isTotalFeeGreaterThanZero > 0) {

          let scids = "";
          data.map((data, index) => {
            let { scid } = data;
            if (scid) {
              scids = scids + "," + String(scid);
            }
          });
          localStorage.setItem("SelectedScids", scids);
          window.location.href = "/InvoiceFirstStep";
          // window.open("/InvoiceFirstStep","_blank")
        } else {
          alert("Can not generate invoice because fees is zero");
        }
      }
    }
  }

  const btnChecked = (e, scid, totalfee) => {
    if (e.target.checked) {
      set_data([...data, { scid: scid }]);
      set_totalFee([...data, { totalfee: totalfee }]);
    } else {
      set_data(data.filter((data) => data.scid !== scid));
      set_totalFee(data.filter((data) => data.totalfee !== totalfee));
    }
    console.log(data);
  };
  //#region desgin
  const desgin = {
    height: "600px",
    overflow: "scroll",
    marginBottom: "50px",
  };
  //#endregion

  return (
    <>
      {Loading ? (
        callForLoader()
      ) : (
        <Container fluid>
          <Row>
            <TopHeading HeadingName={"SCQ For Invoice"} />
          </Row>
          <Container fluid style={desgin}>
            <Row className="mt-5">
              {InvoiceWOSCID == false && RTInvoice == false ? (
                <>
                  <Col sm={2}>
                    <Input
                      placeholder="Search SCID"
                      value={scid}
                      onChange={(e) => set_scid(e.target.value)}
                    />
                  </Col>
                  <Col sm={2}>
                    <SemanticDatepicker
                      placeholder="From Date"
                      onChange={(e, v) => {
                        set_startDate(v.value);
                      }}
                    />
                  </Col>
                  <Col sm={2}>
                    <SemanticDatepicker
                      placeholder="To Date"
                      onChange={(e, v) => {
                        set_endDate(v.value);
                      }}
                    />
                  </Col>
                </>
              ) : null}
              {InvoiceWOSCID ? (
                <><Col sm={3}>
                  <BillingDropDown passChildData={set_BillingLocation} type={1} />
                </Col>
                  <Col sm={3}>
                    <CurrencyDropdown passChildData={set_Currency} />
                  </Col>
                </>) : null}
              {RTInvoice ? (
                <>
                  <Col sm={3}>
                    <CurrencyDropdown passChildData={set_Currency} />
                  </Col>
                </>) : null}
              <Col sm={3}>
                <InvoiceTypeDropdown passChildData={set_invoiceType} />
              </Col>
              <Col sm={2}>
                <Button
                  color="teal"
                  icon="search"
                  onClick={() => {
                    btnSearch();
                  }}
                />
                <Button
                  color="teal"
                  content="Invoice"
                  onClick={() => {
                    btnInvoice();
                  }}
                />

              </Col>
            </Row>

            {loadedData.length > 0 ? (
              <>
                <div className="mt-3">
                  <h5 style={{ fontWeight: "bold", textDecoration: "underline" }}>
                    SCID Information
                  </h5>
                  <table className="table shadow">
                    <thead>
                      <tr key="-11">
                        <th>Select</th>
                        <th> SCID </th>
                        <th> SCQ </th>
                        <th> Name </th>
                        <th> Email</th>
                        <th> Country </th>
                        <th> CCE Name </th>
                        <th> Course Date </th>
                        <th> Mode </th>
                        <th> Location </th>
                        <th> Payment</th>
                        <th> Total Fee</th>
                        <th> Trainer(s)</th>
                      </tr>
                    </thead>

                    {loadedData.length > 0 ? (
                      loadedData.map((c, i) => (
                        <tbody>
                          {c.isInvoiceMade == 1 ? (
                            <tr key={i} style={{ backgroundColor: "#2ecc71", color: "#f5f6fa" }}>
                              <td>
                                <div className="custom-control custom-checkbox">
                                  <MDBInput
                                    label=""
                                    type="checkbox"
                                    onClick={(e) => {
                                      btnChecked(e, c.SCId, c.TotalFee);
                                    }}
                                  />
                                </div>
                              </td>
                              <td>
                                <a href={"https://rms.koenig-solutions.com/Forms/CRM/student-card-prev.aspx?scid=" + c.SCId + "&PDFTran=pdfwerw12312123xfcg"}> {c.SCId}</a>
                              </td>
                              <td>{c.QuotationId}</td>
                              <td>{c.Name}</td>
                              <td>{c.Email}</td>
                              <td>{c.Country}</td>
                              <td>{c.UserName}</td>
                              <td>{c.CourseStartDateTimeZone}</td>
                              <td>{c.Mode}</td>
                              <td>{c.LocationName}</td>
                              <td>
                                {c.Currency} &nbsp; {c.Payment}
                              </td>
                              <td>{c.TotalFee}</td>
                              <td>{c.trainerNames}</td>
                            </tr>
                          ) : (
                            <tr key={i} style={{ backgroundColor: "#f368e0", color: "#f5f6fa" }}>
                              <td>
                                <div className="custom-control custom-checkbox">
                                  <MDBInput label="" type="checkbox"
                                    onClick={(e) => {
                                      btnChecked(e, c.SCId, c.TotalFee);
                                    }}
                                  />
                                </div>
                              </td>
                              <td>
                                <a href={"https://rms.koenig-solutions.com/Forms/CRM/student-card-prev.aspx?scid=" + c.SCId + "&PDFTran=pdfwerw12312123xfcg"}> {c.SCId}</a>
                              </td>
                              <td>{c.QuotationId}</td>
                              <td>{c.Name}</td>
                              <td>{c.Email}</td>
                              <td>{c.Country}</td>
                              <td>{c.UserName}</td>
                              <td>{c.CourseStartDateTimeZone}</td>
                              <td>{c.Mode}</td>
                              <td>{c.LocationName}</td>
                              <td>
                                {c.Currency} &nbsp; {c.Payment}
                              </td>
                              <td>{c.TotalFee}</td>
                              <td>{c.trainerNames}</td>
                            </tr>
                          )}

                        </tbody>
                      ))
                    ) : (
                      <tbody>
                        <tr key="0">
                          <td>Oops... No Records Found</td>
                        </tr>
                      </tbody>
                    )}
                  </table>
                </div>
              </>
            ) : (
              ""
            )}

            <Row>
              {InvoiceData.length > 0 ? (
                <>
                  <div className="mt-3">
                    <h5 style={{ fontWeight: "bold", textDecoration: "underline" }}>
                      Invoice Information
                    </h5>
                    <table className="table shadow">
                      <thead>
                        <tr key="-11">
                          <th>Invoice No</th>
                          <th>Invoice Date </th>
                          <th> Corporate/Client	 </th>
                          <th> Payment Terms </th>
                          <th> Currency</th>
                          <th> Invoice Amount </th>
                          <th> SGST Amt </th>
                          <th> CGST Amt </th>
                          <th> ICGST Amt </th>
                        </tr>
                      </thead>

                      {InvoiceData.length > 0 ? (
                        InvoiceData.map((c, i) => (
                          <tbody>
                            <tr key={i}>
                              <td><a href={"/InvoiceThirdStep?invid=" + c.InvId}> {c.InvoiceNo} </a> </td>
                              <td>{c.InvoiceDate}</td>
                              <td>{c.BuyerName}</td>
                              <td>{c.PayTerms}</td>
                              <td>{c.Currency}</td>
                              <td>{c.InvAmount}</td>
                              <td>{c.InvSGSTAmt}</td>
                              <td>{c.InvCGSTAmt}</td>
                              <td>{c.InvICGSTAmt}</td>

                            </tr>
                          </tbody>
                        ))
                      ) : (
                        <tbody>
                          <tr key="0">
                            <td>Oops... No Records Found</td>
                          </tr>
                        </tbody>
                      )}
                    </table>
                  </div>
                </>
              ) : (
                ""
              )}
            </Row>


            <Row>
              {loadedData.length > 0 ? (
                <>
                  <div className="mt-3">
                    <h5 style={{ fontWeight: "bold", textDecoration: "underline" }}>
                      PO & Client Information
                    </h5>
                    <table className="table shadow">
                      <thead>
                        <tr key="-11">
                          <th>PO No</th>
                          <th>Billing Name </th>
                          <th>Billing address</th>
                          <th>Koenig's Billing Country </th>
                          <th>Contact person for payment </th>
                          <th>Portal details In case of invoice needs </th>

                        </tr>
                      </thead>

                      {loadedData.length > 0 ? (
                        loadedData.map((c, i) => (
                          c.POFile != null ?
                            <tbody>
                              <tr key={i}>
                                <td><a href={"https://rms.koenig-solutions.com/Sync_data/" + c.POFile} target="_blank"> {c.ponum} </a> </td>
                                <td>{c.ClientsBillingNameandBillingaddress}</td>
                                <td>{c.ClientsBillingaddress}</td>
                                <td>{c.KoenigsBillingLocation}</td>
                                <td>{c.Contactpersonforinvoice}</td>
                                <td>{c.Anyadditionalinformation}</td>


                              </tr>
                            </tbody> :
                            <tbody>
                              <tr key={i}>
                                <td>{c.ponum} </td>
                                <td>{c.ClientsBillingNameandBillingaddress}</td>
                                <td>{c.ClientsBillingaddress}</td>
                                <td>{c.KoenigsBillingLocation}</td>
                                <td>{c.Contactpersonforinvoice}</td>
                                <td>{c.Anyadditionalinformation}</td>


                              </tr>
                            </tbody>

                        ))
                      ) : (
                        <tbody>
                          <tr key="0">
                            <td>Oops... No Records Found</td>
                          </tr>
                        </tbody>
                      )}
                    </table>
                  </div>
                </>
              ) : (
                ""
              )}
            </Row>
          </Container>
        </Container>
      )}
    </>

  );
}
